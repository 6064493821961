export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.maxiMenu()
        NavbarComponent.targetEntriesMaxiMenu()
        $(window).resize(() => {
            NavbarComponent.targetEntriesMaxiMenu()
        })
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            const full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else {
                if (document.querySelector('body').classList.contains('home')) {
                    navbar.removeClass('navbar--onscroll')
                }
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if (body.hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static maxiMenu() {
        NavbarComponent.toogleMaxiMenu()
        NavbarComponent.targetEntriesMaxiMenu()
    }

    static toogleMaxiMenu() {
        $('[data-maxi-menu-toggle]').on('click', (e) => {
            e.preventDefault()
            $('body').toggleClass('maxi-menu-is-open')

            if (!$('body').hasClass('maxi-menu-is-open')) {
                NavbarComponent.disableEntries()
            }
        })
    }

    static targetEntriesMaxiMenu() {
        // Trigger du menu en desktop
        $('[data-maxi-menu-active-target]').on('click', function (e) {
            e.preventDefault()

            NavbarComponent.disableEntries()

            if (this.hasAttribute('data-maxi-menu-target')) {
                const attr = $(this).attr('data-maxi-menu-target')
                NavbarComponent.activeEntry(attr)
            }
        })

        const mainEntry = $('.maxi-menu__main-entries__item')
        const mainEntryArrow = $('.maxi-menu__main-entries__item__arrow')
        mainEntry.unbind('hover')
        mainEntryArrow.unbind('click')
        if (window.matchMedia('(min-width: 1001px)').matches) {
            mainEntry.hover(function (e) {
                e.preventDefault()

                NavbarComponent.disableEntries()

                if (this.hasAttribute('data-maxi-menu-target')) {
                    const attr = $(this).attr('data-maxi-menu-target')
                    NavbarComponent.activeEntry(attr)
                }
            })
        } else {
            mainEntryArrow.on('click', function (e) {
                const item = $(this).parent()
                if (item.get(0).hasAttribute('data-maxi-menu-target')) {
                    e.preventDefault()
                }

                NavbarComponent.disableEntries()

                if (item.get(0).hasAttribute('data-maxi-menu-target')) {
                    const attr = $(this).parent().attr('data-maxi-menu-target')
                    NavbarComponent.activeEntry(attr)
                }
            })
            const triggerBack = $('.maxi-menu__header__back')
            triggerBack.unbind('click')
            triggerBack.click((e) => {
                e.preventDefault()
                NavbarComponent.disableEntries()
            })
        }
    }

    static disableEntries() {
        $('.maxi-menu__main-entries__item, .maxi-menu__child-entries__wrapper').removeClass(
            'active',
        )
        $('.maxi-menu__header__logo').css('display', 'flex')
        $('.maxi-menu__header__back').hide()
    }

    static activeEntry(target) {
        $(`#main-entry-${target}, #child-entry-${target}`).addClass('active')
        $('.maxi-menu__header__logo').hide()
        $('.maxi-menu__header__back').css('display', 'flex')
    }
}
