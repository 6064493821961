/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new NavbarComponent()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Front page only
    if ($('.eco-mode-active').length === 0) {
        import(
            '@scripts/components/home-slider.component' /* webpackChunkName: "scripts/home-slider.component" */
        ).then(({ default: HomeSliderComponent }) => {
            new HomeSliderComponent()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }

    //Fullscreen template only
    if ($('.eco-mode-active').length === 0 && $('.reveal').length > 0) {
        import(
            '@scripts/services/reveal.service' /* webpackChunkName: "scripts/reveal.service" */
        ).then(({ default: RevealService }) => {
            new RevealService()
        })
    }
})
